import React, {useState, useEffect} from "react";

import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Axios from 'axios';
import {PLACE_ORDER_URL} from '../../../configs';
import { useHistory, Redirect } from "react-router-dom";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';

export default function Checkout(params){

  // const { resSlug } = useParams();
  const history = useHistory();

  // const [restaurant, setRestaurant] = useState({})
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [partial_wallet, setpartial_wallet] = useState(false);


  // useEffect(()=>{
  //     Axios.post(GET_RESTAURANT_INFO_URL+'/'+resSlug)
  //     .then(res=>{
  //         console.log(res.data)
  //         setRestaurant(res.data)
  //     })
  //     .catch((e)=>console.log(e))
  // }, [resSlug]);

  // useEffect(()=>{
  //     Axios.post(GET_RESTAURANT_ITEMS_URL+'/'+resSlug)
  //     .then(res=>{
  //         // console.log(res.data)
  //         setItems(res.data.items)
  //         setRecommended(res.data.recommended)
  //         setArrayHolderItems(res.data.items)
  //         setArrayHolderRecommended(res.data.recommended)
  //         setLoading(false);
  //     })
  //     .catch((e)=>console.log(e))
  // }, [resSlug]);
  
  useEffect(()=>{
    let user = JSON.parse(localStorage.getItem('user'));
    // let cartRestaurant = JSON.parse(localStorage.getItem('cartRestaurant'));
    let cartItems = JSON.parse(localStorage.getItem('cart'));
    setUser(user);
    // setRestaurant(cartRestaurant);
    setCart(cartItems);
  }, [])

  const payOnline=async(e)=>{
    let total = parseInt(localStorage.getItem('totalPayable'));
    let totalPayable = (partial_wallet?(total - user.wallet_balance)<0?0:total - user.wallet_balance:total);
    if(totalPayable === 0){
      return;
    }
      // const API_URL = 'https://admin.pureeats.in/'
      // // const orderUrl = `${API_URL}order`;
      // // const response = await Axios.get(orderUrl);
      // // const { data } = response;
      // const options = {
      //   key: 'rzp_live_hoLBNo6OWowo4H',
      //   name: "PureEats",
      //   description: "Place order",
      //   order_id: 'test',
      //   handler: async (response) => {
      //     try {
      //      const paymentId = response.razorpay_payment_id;
      //      const url = `${API_URL}capture/${paymentId}`;
      //      const captureResponse = await Axios.post(url, {})
      //      console.log(captureResponse.data);
      //     } catch (err) {
      //       console.log(err);
      //     }
      //   },
      //   theme: {
      //     color: "#686CFD",
      //   },
      // };
      // const rzp1 = new window.Razorpay(options);
      // rzp1.open();
    let options = {
      "key": "rzp_live_uVv0lGLGGr4Ajn",
      "amount": totalPayable*100, // 2000 paise = INR 20, amount in paisa
      "name": "PureEats",
      "description": "Pay for your order.",
      "image": "/assets/img/logos/default.png",
      "handler": function (response){
        // alert(response.razorpay_payment_id);
        placeOrder('RAZORPAY', response.razorpay_payment_id)
      },
      "prefill": {
        "name": JSON.parse(localStorage.getItem('user')).name,
        "email": JSON.parse(localStorage.getItem('user')).email
      },
      "notes": {
        "address": localStorage.getItem('address')
      },
      "theme": {
        "color": "#f90e30"
      }
    };

    let rzp = new window.Razorpay(options);
    rzp.open();
  }

  const placeOrder = (method, payment_id='') => {
    setLoading(true);
    // let userSetAddress = localStorage.getItem("userSetAddress");

    // coupon: {}
    // delivery_type: 1
    // location: {lat: "9.072136", lng: "7.399797",…}
    // method: "COD"
    // order: [{id: 45, restaurant_id: 1, item_category_id: 14, name: "Mango White Chocolate Pancakes",…}]
    // order_comment: null
    // partial_wallet: false
    // payment_token: ""
    // token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2hvdHBvdGFmcmljYWFkbWluLnRlY2hjb25kdWN0b3IuY29tL2FwaS9sb2dpbiIsImlhdCI6MTYwMTM0Nzk2NCwibmJmIjoxNjAxMzQ3OTY0LCJqdGkiOiIwUE1zeEZ5OWRQODZ4aDl4Iiwic3ViIjo3LCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.6lMntaDdn-uKNcb0-pSYp-mgLCz1nNttyiWhErSW3B4"
    // total: {productQuantity: 1, totalPrice: 800}
    // user: {success: true, data: {id: 7,…}, running_order: null, delivery_details: null}

    // console.log({
    //       token: user.auth_token,
    //       user: JSON.parse(localStorage.getItem('state')).user.user,
    //       order: cart,
    //       coupon: {},
    //       location: {...JSON.parse(userSetAddress), address:localStorage.getItem("address")},
    //       order_comment: null,
    //       total: {productQuantity: cart.length, totalPrice: localStorage.getItem('totalPayable')},
    //       method: 'COD',
    //       payment_token: '',
    //       delivery_type: 1,
    //       partial_wallet: false
    //   });
    // return;
     
     let order_method = method;
     let total = parseInt(localStorage.getItem('totalPayable'));
     let tip = parseInt(localStorage.getItem('tip'));
     let totalPayable = (partial_wallet?(total - user.wallet_balance)<0?0:total - user.wallet_balance:total);

     if(totalPayable === 0 || method==='WALLET'){
      order_method = 'WALLET';
      totalPayable = total;
     }
    
      let raw = {
          token: user.auth_token,
          user: {delivery_details: null, running_order: null, success: true, data: user},
          order: cart,
          coupon: ((localStorage.getItem("applyedCoupon") !== null)?{code:JSON.parse(localStorage.getItem("applyedCoupon")).code}:''),
          location: {address:user.default_address.address, lat: user.default_address.latitude, lng: user.default_address.longitude, house: '', tag: null},
          order_comment: '',
          total: total,
          payable: method==='WALLET'?total:totalPayable,
          driver_tip_amount: tip,
          method: order_method,
          payment_token: '',
          payment_id: payment_id,
          delivery_type: localStorage.getItem('delivery_type') === 'self'?2:1,
          partial_wallet: method==='WALLET'?true:partial_wallet
      };

      console.log(raw);
      // return;

      Axios.post(PLACE_ORDER_URL, raw)
      .then(response => {
          const checkout = response.data;

          if (checkout.success) {
              // dispatch({ type: PLACE_ORDER, payload: checkout });

              // const state = getState();
              // console.log(state);
              // const cartProducts = state.cart.products;
              // const user = state.user.user;
              localStorage.removeItem("orderComment");
              localStorage.removeItem("cart");
              localStorage.removeItem("cartRestaurant");
              localStorage.removeItem("applyedCoupon");
              localStorage.removeItem("totalPayable");
              localStorage.removeItem("tip");

              history.push('myOrders');

              // for (let i = cartProducts.length - 1; i >= 0; i--) {
              //     // remove all items from cart
              //     cartProducts.splice(i, 1);
              // }

              // dispatch(updateCart(cartProducts));
          }

          setLoading(false);
      })
      .catch(function(error) {
          setLoading(false);
          console.log(error);
      });
  };

  const makeUseWallet=()=>{
    if(user.wallet_balance > 0){
      setpartial_wallet(!partial_wallet)
      // if(parseInt(localStorage.getItem('totalPayable')) - user.wallet_balance < 0){
      //   placeOrder('WALLET');
      // }
    }
  }

  const minusWalletRemaning=()=>{
    let t = parseInt(localStorage.getItem('totalPayable'))
    let r = t - user.wallet_balance;
    return r>0?"0":Math.abs(r)
  }

  if(cart===null){
    return(<Redirect to="/restaurants" />);
  }


  return (
    <div className="container-fluid">

        <div className="row mb-4">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>

            <Typography style={{display: 'inline'}} variant="h6">
              <b>PAYMENTS</b>
            </Typography><br />
            <Typography style={{display: 'inline', 'marginLeft': '13%',position: 'absolute',top: 29}} className="text-muted" variant="body2">
              {cart.length} items, To pay: ₹ {localStorage.getItem('totalPayable')}, Delivery Type: {localStorage.getItem('delivery_type') === 'self'?'Self Pickup':'Home Delivery'}
            </Typography>
          </div>
        </div>

        <CardActionArea onClick={()=>makeUseWallet()}>
        <div className="row">
          <div className="col-3 p-0 text-center">
            <img alt="..." src="/assets/img/various/wallet.png" className="img-fluid" />
          </div>
          <div className="col-6 p-0">
            <CardContent className="p-1">
              <Typography gutterBottom variant="body1" component="h2">
                <b>WALLET </b> ₹ {partial_wallet?minusWalletRemaning():user.wallet_balance}
              </Typography>
            </CardContent>
          </div>
          {partial_wallet && (
          <div className="col-3 p-0 text-center">
            <img style={{width:58}} alt="..." src="/assets/img/check.png" className="img-fluid" />
          </div>
          )}
        </div>
        </CardActionArea>

        <div className="row" style={{ marginTop: 20, marginBottom: 10}}>
        {(partial_wallet && minusWalletRemaning()==="0") && (
        <div className="col-9">
          <Typography variant="caption"><b>COD not available when using wallet</b></Typography>

          <Button disabled={loading} onClick={()=>payOnline()} variant="contained" color="primary" style={{width: '100%', height: 40, color: 'white', marginTop: 10}}>
            {(loading)?'Please Wait...':'PAY ONLINE REMANING ₹ '+(partial_wallet?((parseInt(localStorage.getItem('totalPayable')) - user.wallet_balance)<0?"0":parseInt(localStorage.getItem('totalPayable')) - user.wallet_balance):localStorage.getItem('totalPayable'))}
          </Button>
        </div>
        )}

        {(partial_wallet && minusWalletRemaning()>0) && (
        <div className="col-9">
          <Typography variant="caption"><b>COD not available when using wallet</b></Typography>

          <Button disabled={loading} onClick={()=>placeOrder('WALLET')} variant="contained" color="primary" style={{width: '100%', height: 40, color: 'white', marginTop: 10}}>
            {(loading)?'Please Wait...':'PLACE ORDER' }
          </Button>
        </div>
        )}
        </div>

        <div className="row" style={{borderBottom: '1px solid grey', marginTop: 20, marginBottom: 10}}>
            <div className="col-12 p-3">
              <Typography variant="caption"><b>WALLETS</b></Typography>
            </div>
            <div className="col-3 text-center p-3">
              <img onClick={()=>payOnline()} alt='imagehere' src="/assets/img/android/razorpay.png" className="img-fluid" style={{width: 54}} />
            </div>
            <div className="col-9 p-3">
              <Typography onClick={()=>payOnline()} variant="body1"><b>Razorpay</b></Typography>
            </div>
        </div>

        <div className="row">
            <div className="col-12 p-3">
              <Typography variant="caption"><b>PAY ON DELIVERY</b></Typography>
            </div>
        </div>

        <div className="row" style={{marginBottom: 75}}>
            <div className="col-3 text-center">
              <img alt='imagehere' src="/assets/img/android/rupees.png" className="img-fluid" style={{width: 54}} />
            </div>


            {!partial_wallet && (
            <div className="col-9">
              <Typography variant="caption"><b>Cash</b></Typography>
              {(localStorage.getItem('delivery_type') === 'self' || parseInt(localStorage.getItem('subTotal')) > 400)  && (
                <>
                  <Typography variant="body2">Cash on Delivery is not available</Typography>
                  <Typography variant="body2">Please pay using online payment method</Typography>
                </>
              )}

              {(localStorage.getItem('delivery_type') !== 'self' && parseInt(localStorage.getItem('subTotal')) < 400)  && (
                <Button disabled={loading} onClick={()=>placeOrder('COD')} variant="contained" color="primary" style={{width: '100%', height: 40, color: 'white', marginTop: 10}}>
                  {(loading)?'Please Wait...':'PAY WITH CASH ₹ '+(partial_wallet?((parseInt(localStorage.getItem('totalPayable')) - user.wallet_balance)<0?"0":parseInt(localStorage.getItem('totalPayable')) - user.wallet_balance):localStorage.getItem('totalPayable'))}
                </Button>
              )}

              {(localStorage.getItem('delivery_type') !== 'self' && parseInt(localStorage.getItem('subTotal')) > 400)  && (
                <Button disabled={true} onClick={()=>placeOrder('COD')} variant="contained" color="primary" style={{width: '100%', height: 40, color: '#555555', marginTop: 10}}>
                  COD not allowed on order amount above ₹ 400
                </Button>
              )}

            </div>
            )}
        </div>

    </div>
  );
}

