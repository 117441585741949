import React, {useState, useEffect} from "react";

import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
// import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Axios from 'axios';
import {GET_RESTAURANT_ITEMS_URL, GET_RESTAURANT_INFO_URL, ROOT_URL} from '../../../configs';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from "react-router-dom";
import Grow from '@material-ui/core/Grow';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasketOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import { useParams } from "react-router-dom";
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Fab from '@material-ui/core/Fab';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CallIcon from '@material-ui/icons/Call';
import DirectionsOutlined from '@material-ui/icons/DirectionsOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';

export default function RestaurantDetail(params){

  const { resSlug } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [restaurant, setRestaurant] = useState({})
  const [recommended, setRecommended] = useState([]);
  const [arrayHolderRecommended, setArrayHolderRecommended] = useState([]);
  const [items, setItems] = useState([]);
  const [arrayHolderItems, setArrayHolderItems] = useState([]);
  // const [scrolled, setScrolled] = useState(false);
  // const [vegOnly, setVegOnly] = useState(false);
  // const [favourite, setFavourite] = useState(false);
  const [filterVegOnly, setFilterVegOnly] = useState(false);
  const [filterIncludesEgg, setFilterIncludesEgg] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const [detailView, setDetailView] = useState(false);
  const [detailViewData, setDetailViewData] = useState({});
  
  const [cart, setCart] = useState([]);
  const [search, setSearch] = useState('');
  const [time, setTime] = useState('...');

  useEffect(()=>{
    if(localStorage.getItem('cart') !== null){
      setCart(JSON.parse(localStorage.getItem('cart')))
    }
  },[])

  useEffect(()=>{
      Axios.post(GET_RESTAURANT_INFO_URL+'/'+resSlug)
      .then(res=>{
          console.log(res.data)
          setRestaurant(res.data);
          calculateDistance(res.data, res.data.latitude,res.data.longitude);
      })
      .catch((e)=>console.log(e))
  }, [resSlug]);

  useEffect(()=>{
      Axios.post(GET_RESTAURANT_ITEMS_URL+'/'+resSlug)
      .then(res=>{
          // console.log(res.data)
          setItems(res.data.items)
          setRecommended(res.data.recommended)
          setArrayHolderItems(res.data.items)
          setArrayHolderRecommended(res.data.recommended)
          setLoading(false);
      })
      .catch((e)=>console.log(e))
  }, [resSlug]);

  const calculateDistance=async(data, lat, lng)=>{
    if(localStorage.getItem('delivery_type') === 'delivery') {
      var target_url = `https://admin.pureeats.in/googleDirectionCall?olat=${localStorage.getItem("userLat")}&olng=${localStorage.getItem("userLng")}&dlat=${lat}&dlng=${lng}`;

      let resp = await fetch(`${target_url}`)
      let respJson = await resp.json();
      let dt = Math.round((respJson.rows[0].elements[0].duration.value / 60) + Number(data.delivery_time));
      setTime(String(dt)+' min')
    } else{
      setTime(data?.delivery_time+' min')
    }
  }

  const filterItemsVeg=(type, checked)=>{

    setFilterVegOnly((type==='veg'?checked:false))
    setFilterIncludesEgg((type==='egg'?checked:false))
    
    let newData = arrayHolderRecommended.filter(item => checked?((type==='veg')?item.is_veg === 1:item.is_veg !== 1):item);
    setRecommended(newData);

    let newItems = {};
    Object.keys(arrayHolderItems).map((keys)=>{
      let newData2 = newItems[keys]=arrayHolderItems[keys].filter(item=> checked?((type==='veg')?item.is_veg === 1:item.is_veg !== 1):item);
      return newItems[keys]=newData2;
    });
    setItems(newItems);
  }

  const addToCart=(item)=>{
    if(localStorage.getItem("cartRestaurant") !== null){
      if(JSON.parse(localStorage.getItem("cartRestaurant")).id !== restaurant.id){
        localStorage.removeItem('cart');
        localStorage.removeItem('cartRestaurant');
        localStorage.removeItem('applyedCoupon');
        setCart([{...item, quantity: 1, cartPrice: item.price}])
        localStorage.setItem('cartRestaurant', JSON.stringify(restaurant));
        localStorage.setItem('cart', JSON.stringify([{...item, quantity: 1, cartPrice: item.price}]));
        return;
      }
    }
    setCart([...cart, {...item, quantity: 1, cartPrice: item.price}])
    localStorage.setItem('cartRestaurant', JSON.stringify(restaurant));
    localStorage.setItem('cart', JSON.stringify([...cart, {...item, quantity: 1, cartPrice: item.price}]));
  }

  const incrementCart=(citem)=>{
    console.log(citem)
    let item = cart.filter((i)=>i.id === citem.id)[0]
    let newItems = cart.filter((i)=>i.id !== citem.id)
    item.quantity=item.quantity+1;
    item.cartPrice=parseInt(item.price)*(item.quantity)
    newItems.push(item)
    setCart(newItems)
    localStorage.setItem('cart', JSON.stringify(newItems));
  }

  const decrementCart=(citem)=>{
    console.log(citem)
    let item = cart.filter((i)=>i.id === citem.id)[0]
    if (item.quantity === 1) {
      let newItems = cart.filter((i)=>i.id !== citem.id)
      item.cartPrice=item.cartPrice - parseInt(item.price)
      setCart(newItems)
      localStorage.setItem('cart', JSON.stringify(newItems));
      if(newItems.length===0){
        localStorage.removeItem('cart');
      }
    }else{
      let newItems = cart.filter((i)=>i.id !== citem.id)
      item.quantity = item.quantity-1
      item.cartPrice=parseInt(item.price)*(item.quantity-1)
      newItems.push(item)
      setCart(newItems)
      localStorage.setItem('cart', JSON.stringify(newItems));
    }
  }

  function subTotal(){
    let total = 0;
    cart.map((i)=>total+=parseInt(i.cartPrice))
    console.log(total)
    return String(total).replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
  }

  const searchItems=(text)=>{
    setSearch(text);

    let newData = arrayHolderRecommended.filter(item => {      
      let itemData = `${String(item.name).toUpperCase()}`;
      let textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;    
    });
    setRecommended(newData);

    let newItems = {};
    Object.keys(arrayHolderItems).map((keys)=>{
      let newData2 = newItems[keys]=arrayHolderItems[keys].filter(item=>{
        let itemData = `${String(item.name).toUpperCase()}`;
        let textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;    
      });
      return newItems[keys]=newData2;
    });
    setItems(newItems);
  }

  const copyText=()=>{
    navigator.clipboard.writeText(restaurant.address)
    alert("Address Copied.");
  }


    return (
        <div className="container-fluid">

            <div className="row">
              <div className="col-12">
                <FormControl style={{width: '100%'}}>
                  <Input
                    style={{paddingTop: 9, paddingBottom: 9}}
                    id="search"
                    placeholder="Search Plate..."
                    value={search}
                    onChange={(e)=>searchItems(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton onClick={()=>history.goBack()} aria-label="back">
                          <KeyboardBackspaceIcon style={{marginRight: 5}} />
                        </IconButton>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchOutlined style={{marginRight: 13}} />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </div>

            {loading && (
            <div className="row mt-2">
                <div className="col-4">
                    <div className="mb-3">
                      <Skeleton variant="rect" height={85} />
                    </div>
                </div>
                <div className="col-8">
                    <div className="mb-3">
                      <Skeleton variant="text" style={{marginTop: 1}} />
                      <Skeleton variant="text" width={100} />
                      <Skeleton variant="text" width={70} />
                    </div>
                </div>
            </div>
            )}

            {!loading && search==='' && (
            <><div className="row m-1" style={{borderBottom: '1px dashed grey'}}>
                <div className="col-sm-12">
                  <div className="row mb-3">
                    <div className="col-4 col-md-4 p-0">
                      <div className="p-2">
                        <img alt="Pureeats" className="img-fluid" src={"https://admin.pureeats.in/"+restaurant.image} />
                      </div>
                    </div>
                    <div className="col-8 col-md-8 p-0">
                      <div className="p-2">
                        <Typography variant="body1">
                          <b>{restaurant.name}</b>
                        </Typography>
                        <Typography variant="body2" style={{lineHeight: '14px', color: 'grey'}}>
                          {restaurant.description}
                        </Typography>
                        <Typography className="text-danger" variant="body2">
                          {restaurant.address}
                        </Typography>

                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-5 p-0">
                          <Button size="small" onClick={()=>copyText()} aria-label="copy address">
                            <CheckBoxOutlineBlankOutlinedIcon className="mr-1" />
                            Copy Address
                          </Button>
                        </div>
                        <div className="col-4">
                          <Button size="small" onClick={()=>window.location = (`http://maps.google.com/maps?saddr=${restaurant.latitude},${restaurant.longitude}&daddr=${JSON.parse(localStorage.getItem('userLat'))},${JSON.parse(localStorage.getItem('userLng'))}`)} aria-label="direction">
                            <DirectionsOutlined className="mr-1" />
                            Direction
                          </Button>
                        </div>
                        <div className="col-2">
                          <Button size="small" onClick={()=>{window.location = (`tel:${restaurant.contact_number}`)}} aria-label="call">
                            <CallIcon className="mr-1" />
                            Call
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            
            <div className="row m-1 p-3" style={{marginTop: 8, borderBottom: '1px solid grey'}}>
              <div className="col-4 col-md-4">
                <div className="text-center">
                  <Link to={"/restaurantRatings/"+restaurant.id} >
                    <><Typography style={{color: 'black'}} variant="caption"><StarIcon /> {restaurant.rating}</Typography><br />
                    <Typography style={{color: '#000000ba'}} variant="caption">Ratings</Typography></>
                  </Link>
                </div>
              </div>
              <div className="col-4 col-md-4">
                <div className="text-center">
                  <Typography style={{color: 'black'}} variant="caption">₹ {restaurant.price_range}</Typography><br />
                  <Typography style={{color: '#000000ba'}} variant="caption">For Two</Typography>
                </div>
              </div>
              <div className="col-4 col-md-4">
                <div className="text-center">
                  <Typography style={{color: 'black'}} variant="caption">{time}</Typography><br />
                  <Typography style={{color: '#000000ba'}} variant="caption">Delivery Time</Typography>
                </div>
              </div>
            </div>
            
            <div className="row m-1">
              <div className="col-12">
                <FormGroup row>
                  <FormControlLabel
                    control={<Switch checked={filterVegOnly} onChange={(e)=>filterItemsVeg('veg', e.target.checked)} name="filterVegOnly" />}
                    label="Veg Only"
                    color="success"
                  />
                  <FormControlLabel
                    control={<Switch checked={filterIncludesEgg} onChange={(e)=>filterItemsVeg('egg', e.target.checked)} name="filterIncludesEgg" />}
                    label="Includes Egg"
                    color="warning"
                  />
                </FormGroup>
              </div>
            </div>
            </>
            )}

            {loading && (
            <div className="row">
                {[...Array(6)].map((i, index)=>{
                    return <div key={index} className="col-6">
                        <div className="mb-3">
                          <Skeleton variant="rect" height={150} />
                          <Skeleton variant="text" style={{marginTop: 5}} />
                          <Skeleton variant="text" width={100} />
                        </div>
                    </div>
                })}
            </div>
            )}

            <div className={(restaurant.is_open===0 || restaurant.is_active===0)?'page-inactive row m-1':'row m-1'}>
              <div className="col-12">
                <Typography id="#Recommended" variant="h6"><b>Recommended</b></Typography>
                <div className="row dashed-bottom">
                  
                  {recommended.map((item, index)=>{
                    return <Grow in={true} key={index}><div  className="col-6 col-md-4 p-0 mb-3">
                        <div className={item.is_active === 0?'page-inactive p-1':'p-1'}>
                          <CardContent className="p-1">
                            <CardActionArea onClick={() => {setDetailViewData(item); setDetailView(true)}}>
                              <div style={{minWidth: '-webkit-fill-available', width: '100%', maxHeight: 180,minHeight: '110px', backgroundColor: '#dcdcdc'}}>
                                {item.image!==null && (
                                  <img className="img-fluid" src={ROOT_URL+item.image} alt="PureEats" />
                                )}
                              </div>
                              {item.is_veg===1 && (
                                <img alt="..." src="/assets/img/various/veg-icon.png" style={Styles.vegIcon} />
                              )}
                              {item.is_veg!==1 && (
                                <img alt="..." src="/assets/img/various/non-veg-icon.png" style={Styles.vegIcon} />
                              )}
                            </CardActionArea>

                            <div style={{marginTop: 10}}>
                              <Typography style={{paddingBottom: 6, paddingTop: 6}} variant="body2" color="textSecondary" component="p">
                                {(item.old_price!=="0.00")?<><span style={{textDecoration: 'line-through', color:'red'}}>₹ {item.old_price}</span> <span style={{color:'green'}}>({parseInt((parseInt(item.old_price)*(parseInt(item.old_price)-parseInt(item.price)))/100)}% Off)</span></>:''}
                              </Typography>
                              <Typography style={{minHeight: 42}} gutterBottom variant="body1" component="h2">
                                {item.name}
                              </Typography>
                              <Typography variant="caption" component="span">
                                { <div dangerouslySetInnerHTML={{ __html: item.desc }} /> }
                              </Typography>
                              <Typography className="mr-3" variant="body2" color="textSecondary" component="span">
                                ₹ {item.price}
                              </Typography>
                              {cart.length === 0 && (
                                <Button onClick={()=>addToCart(item)} style={{
                                  width: 65,
                                  background: 'white',
                                  color: 'green',
                                  borderRadius: 0,
                                  right: 5,
                                  bottom: 4,
                                  position: 'absolute',
                                }} variant="contained">ADD</Button>
                              )}

                              {cart.filter(i => i.id !== item.id).length !== 0 && (
                                <Button onClick={()=>addToCart(item)} style={{
                                  width: 80,
                                  textAlign: 'center',
                                  background: 'white',
                                  color: 'green',
                                  borderRadius: 0,
                                  right: 5,
                                  bottom: 4,
                                  position: 'absolute',
                                }} variant="contained">ADD</Button>
                              )}

                              {cart.some(i => i.id === item.id) && (
                              <div className="MuiButton-contained" style={{
                                width: 80,
                                textAlign: 'center',
                                background: 'white',
                                color: 'green',
                                borderRadius: 0,
                                padding: 3,
                                position: 'absolute',
                                bottom: 4,
                                right: 5,
                              }}>
                                <IconButton onClick={()=>incrementCart(item)} style={{float: 'right'}} size="small" aria-label="plus">
                                  <AddIcon fontSize="small" />
                                </IconButton>
                                <p style={{paddingLeft: 1,marginTop: 3,display: 'inline-block',marginBottom: 0}}>{cart.filter(a=>a.id===item.id)[0].quantity}</p>
                                <IconButton onClick={()=>decrementCart(item)} style={{float: 'left'}} size="small" aria-label="delete">
                                  <RemoveIcon fontSize="small" />
                                </IconButton>
                              </div>
                              )}
                            </div>

                            
                          </CardContent>
                        </div>
                    </div></Grow>
                  })}

                </div>
              </div>
            </div>

            {Object.keys(items).map((keys, index)=>{
              return (<div key={index} className={(restaurant.is_active===0 || restaurant.is_open===0)?'page-inactive row dashed-bottom':'row dashed-bottom'}>
              
                <h4 id={`res-${keys}`} className={'m-3 pt-3'}><b>{keys}</b></h4>
                
                {items[keys].map((i)=>{
                  return (<div key={i.id} className="col-12">
                      <div className={i.is_active === 0?'page-inactive p-3':'p-3'}>
                          <div className="row">
                              <div className="col-3 p-0">
                                <CardActionArea onClick={() => {setDetailViewData(i); setDetailView(true)}}>
                                  <>
                                  {i.is_veg===1 && (
                                    <img alt="..." src="/assets/img/various/veg-icon.png" style={Styles.vegIcon} />
                                  )}
                                  {i.is_veg!==1 && (
                                    <img alt="..." src="/assets/img/various/non-veg-icon.png" style={Styles.vegIcon} />
                                  )}
                                  <div style={{width: 75, maxHeight: 180, minWidth: 54, minHeight: '54px', backgroundColor: '#dcdcdc'}}>
                                    {i.image!==null && (
                                      <img className="img-fluid" src={ROOT_URL+i.image} alt="PureEats" />
                                    )}
                                    {i.image===null && (
                                      <img className="img-fluid page-inactive" src='/assets/img/logos/default.png' alt="PureEats" />
                                    )}
                                  </div>
                                  </>
                                </CardActionArea>
                              </div>
                            <div className="col-6 p-0">
                              <CardActionArea onClick={() => {setDetailViewData(i); setDetailView(true)}}>
                                <CardContent className="p-1">
                                  <Typography gutterBottom variant="body1" component="h2">
                                    <b>{i.name}</b>
                                  </Typography>
                                  <Typography variant="caption" component="span">
                                    { <div dangerouslySetInnerHTML={{ __html: i.desc }} /> }
                                  </Typography>

                                  <Typography variant="body2" color="textSecondary" component="p">
                                    {(i.old_price!=="0.00")?<><span style={{textDecoration: 'line-through', color:'red'}}>₹ {i.old_price}</span> <span style={{color: 'green', fontSize: 15}}>₹ {i.price}</span></>:i.price}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </div>
                            <div className="col-3">
                              {cart.length === 0 && (
                                <Button onClick={()=>addToCart(i)} style={{
                                  width: 80,
                                  textAlign: 'center',
                                  marginLeft: 'auto',
                                  position: 'absolute',
                                  bottom: 5,
                                  left: '20%',
                                  background: 'white',
                                  color: 'green',
                                  borderRadius: 0,
                                }} variant="contained">ADD</Button>
                              )}
                              {cart.filter(a => a.id !== i.id).length !== 0 && (
                                <Button onClick={()=>addToCart(i)} style={{
                                  width: 80,
                                  marginLeft: 'auto',
                                  textAlign: 'center',
                                  position: 'absolute',
                                  bottom: 5,
                                  left: '20%',
                                  background: 'white',
                                  color: 'green',
                                  borderRadius: 0,
                                }} variant="contained">ADD</Button>
                              )}

                              {cart.some(a => a.id === i.id) && (
                              <div className="MuiButton-contained" style={{
                                width: 80,
                                marginLeft: 'auto',
                                position: 'absolute',
                                bottom: 5,
                                left: '20%',
                                background: 'white',
                                color: 'green',
                                borderRadius: 0,
                                padding: 3,
                              }}>
                                <IconButton onClick={()=>incrementCart(i)} style={{float: 'right'}} size="small" aria-label="plus">
                                  <AddIcon fontSize="small" />
                                </IconButton>
                                <p style={{paddingLeft: 7,marginTop: 3,display: 'inline-block',marginBottom: 0}}>{cart.filter(a=>a.id===i.id)[0].quantity}</p>
                                <IconButton onClick={()=>decrementCart(i)} style={{float: 'left'}} size="small" aria-label="delete">
                                  <RemoveIcon fontSize="small" />
                                </IconButton>
                              </div>
                              )}
                            </div>
                          </div>
                      </div>
                  </div>)
              })}</div>)
            })}

            <div className="row">
              <div className="col-12">
                <div className="p-3" style={{marginBottom: '4rem', marginTop: '2rem'}}>
                  <img alt="..." style={{width: 90,marginBottom: 10}} src="/assets/img/android/fssai.png" className="img-fluid" />
                  <h4 style={{color: '#565656',fontWeight: 'bold'}}>{restaurant.certificate}</h4>
                </div>
              </div>
            </div>


            <Fab aria-controls="restaurant-menu" aria-haspopup="true" onClick={(e)=>setAnchorEl(e.currentTarget)} style={{position: 'fixed',left: '40%',height: '30px', bottom:(cart.length > 0)?60:10}} variant="extended" color="secondary" aria-label="Menu">
              Menu
            </Fab>


            <Menu
              id="restaurant-menu"
              anchorEl={anchorEl}
              keepMounted
              style={{left: '0px'}}
              open={Boolean(anchorEl)}
              onClose={(e)=>setAnchorEl(null)}
            >
              <MenuItem 
                key={'Recommended'} 
                style={{borderBottom: '1px dashed grey'}} 
                onClick={(e)=>setAnchorEl(null)}>
                <a href={`#res-${'Recommended'}`}>{'Recommended'}</a>
              </MenuItem>

              {Object.keys(items).map((keys, index)=>{
                return (
                  <MenuItem 
                    key={index} 
                    style={{borderBottom: '1px dashed grey'}} 
                    onClick={(e)=>setAnchorEl(null)}>
                    <a href={`#res-${keys}`}>{keys}</a>
                  </MenuItem>
               )
              })}
            </Menu>

            {cart.length > 0 && (
            <div className="row p-2" style={{position: 'fixed', backgroundColor: 'green', width: '100%', bottom: 0, height: 46, zIndex: 999999}}>
              <div className="col-6">
                <Typography className="text-white" variant="h6">{cart.length} Item | ₹ {subTotal()}</Typography>
              </div>
              <div className="col-6">
                <Link to={"/cart"} >
                  <Typography className="text-white float-right" variant="h6">View Cart <ShoppingBasketIcon className="ml-2 pt-1" /></Typography>
                </Link>
              </div>
            </div>
            )}

            <Drawer
              anchor='bottom'
              open={detailView}
              onClose={() => setDetailView(false)}
            >
              <div className="row row bg-white" style={{margin: '10px 10px 90px 10px'}} >
                {/* <img className="img-fluid" src={ROOT_URL+detailViewData?.image} alt="PureEats" />

                <Typography style={{paddingBottom: 6, paddingTop: 6}} variant="body2" color="textSecondary" component="p">
                  {(detailViewData?.old_price!=="0.00")?<>
                    <span style={{textDecoration: 'line-through', color:'red'}}>
                      ₹ {detailViewData?.old_price}
                    </span> 
                  <span style={{color:'green'}}>
                    ({parseInt((parseInt(detailViewData?.old_price)*(parseInt(detailViewData?.old_price)-parseInt(detailViewData?.price)))/100)}% Off)
                  </span></>:''}
                </Typography> */}

                <CardContent className="p-1">
                  <CardActionArea onClick={() => {setDetailViewData(detailViewData); setDetailView(true)}}>
                    <div style={{minWidth: '-webkit-fill-available', width: '100%', minHeight: '110px', backgroundColor: '#dcdcdc'}}>
                      {detailViewData.image!==null && (
                        <img className="img-fluid" src={ROOT_URL+detailViewData.image} alt="PureEats" />
                      )}
                    </div>
                    {detailViewData.is_veg===1 && (
                      <img alt="..." src="/assets/img/various/veg-icon.png" style={Styles.vegIcon} />
                    )}
                    {detailViewData.is_veg!==1 && (
                      <img alt="..." src="/assets/img/various/non-veg-icon.png" style={Styles.vegIcon} />
                    )}
                  </CardActionArea>

                  <div style={{marginTop: 10}}>
                    <Typography style={{paddingBottom: 6, paddingTop: 6}} variant="body2" color="textSecondary" component="p">
                      {(detailViewData.old_price!=="0.00")?<><span style={{textDecoration: 'line-through', color:'red'}}>₹ {detailViewData.old_price}</span> <span style={{color:'green'}}>({parseInt((parseInt(detailViewData.old_price)*(parseInt(detailViewData.old_price)-parseInt(detailViewData.price)))/100)}% Off)</span></>:''}
                    </Typography>

                    <Typography style={{minHeight: 27}} gutterBottom variant="body1" component="h2">
                      {detailViewData.name}
                    </Typography>
                    <Typography variant="caption" component="span">
                      { <div dangerouslySetInnerHTML={{ __html: detailViewData.desc }} /> }
                    </Typography>
                    
                    <Typography className="mr-3" variant="body2" color="textSecondary" component="span">
                      ₹ {detailViewData.price}
                    </Typography>
                    
                    {cart.length === 0 && (
                      <Button onClick={()=>addToCart(detailViewData)} style={{
                        width: 65,
                        background: 'white',
                        color: 'green',
                        borderRadius: 0,
                        right: 5,
                        bottom: 94,
                        position: 'absolute',
                      }} variant="contained">ADD</Button>
                    )}

                    {cart.filter(i => i.id !== detailViewData.id).length !== 0 && (
                      <Button onClick={()=>addToCart(detailViewData)} style={{
                        width: 80,
                        textAlign: 'center',
                        background: 'white',
                        color: 'green',
                        borderRadius: 0,
                        right: 5,
                        bottom: 94,
                        position: 'absolute',
                      }} variant="contained">ADD</Button>
                    )}

                    {cart.some(i => i.id === detailViewData.id) && (
                    <div className="MuiButton-contained" style={{
                      width: 80,
                      textAlign: 'center',
                      background: 'white',
                      color: 'green',
                      borderRadius: 0,
                      padding: 3,
                      position: 'absolute',
                      bottom: 94,
                      right: 5,
                    }}>
                      <IconButton onClick={()=>incrementCart(detailViewData)} style={{float: 'right'}} size="small" aria-label="plus">
                        <AddIcon fontSize="small" />
                      </IconButton>
                      <p style={{paddingLeft: 1,marginTop: 3,display: 'inline-block',marginBottom: 0}}>{cart.filter(a=>a.id===detailViewData.id)[0].quantity}</p>
                      <IconButton onClick={()=>decrementCart(detailViewData)} style={{float: 'left'}} size="small" aria-label="delete">
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                    </div>
                    )}
                  </div>

                  
                </CardContent>


              </div>
            </Drawer>


        </div>
    );
}

const Styles = {
  vegIcon: {
    width: '16px',
    height: '16px',
    position: 'absolute',
    left: '10px',
    top: '10px',
    background: 'white',
  },
}
