import React, {useState, useEffect} from "react";

import Typography from '@material-ui/core/Typography';
// import {ROOT_URL} from '../../../configs';
import { useHistory, Link } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CallIcon from '@material-ui/icons/Call';
import DraftsIcon from '@material-ui/icons/Drafts';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import StarOutlineOutlinedIcon from '@material-ui/icons/StarOutlineOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
// import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import MyLocationOutlinedIcon from '@material-ui/icons/MyLocationOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

export default function Account(params){

  const history = useHistory();

  const [account, setAccount] = useState({});
  // const [loading, setLoading] = useState(true);

  useEffect(()=>{
      setAccount(JSON.parse(localStorage.getItem('user')))
  }, []);

  const logout=()=>{
    localStorage.removeItem('user');
    localStorage.removeItem('userLng');
    localStorage.removeItem('userLat');
    localStorage.removeItem('cartRestaurant');
    localStorage.removeItem('cart');
    localStorage.removeItem('address');
    localStorage.removeItem('fromCart');
    localStorage.removeItem('applyedCoupon');
    localStorage.removeItem('subTotal');
    localStorage.removeItem('delivery_type');
    history.push('/');
  }

  const shareApp=()=>{
    if (navigator.canShare) {
      navigator.share({
        url: 'https://pureeats.in',
        title: 'Pureeats',
        text: 'Your taste - our passion',
      })
      .then(() => console.log('Share was successful.'))
      .catch((error) => console.log('Sharing failed', error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }

  return (
    <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {account!==null && (
              <div className="col-12"><Typography className="ml-2 float-left" color="text-secondary" variant="h6">
                <b>{account.name}</b>
              </Typography>
              <Typography className="ml-2 float-right text-danger" variant="body2">
                <Link to="/editAccount"><b>Edit</b></Link>
              </Typography></div>
            )}
            {account===null && (
              <Typography className="ml-2 mb-2" color="text-secondary" variant="h6">
              <b>PURE EATS</b>
              </Typography>
            )}
          </div>
        </div>

        {account!==null && (

        <div className="row">
          <div className="col-12">
            <List component="nav" aria-label="main mailbox folders">
              <ListItem button>
                <ListItemIcon>
                  <CallIcon />
                </ListItemIcon>
                <ListItemText primary={account.phone} secondary="Mobile" />
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <DraftsIcon />
                </ListItemIcon>
                <ListItemText primary={account.email} secondary="Email" />
              </ListItem>
            </List>
          </div>
        </div>
        )}

        
        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

        {account===null && (
          <><div className="row">
            <div className="col-12">
              <List component="nav" aria-label="main mailbox folders">
                <ListItem onClick={()=>history.push('/login')} button>
                  <ListItemIcon>
                    <AccountCircleOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Login" />
                </ListItem>
              </List>
            </div>
          </div>
          <div className="row" style={{backgroundColor: '#dedede'}}>
            <div className="col-12">
            <br />
            </div>
          </div></>
        )}


        <div className="row">
          <div className="col-12">
            <List component="nav" aria-label="main mailbox folders">
              {account!==null && (
              <><ListItem onClick={()=>history.push('/manageAddress')} button>
                <ListItemIcon>
                  <MyLocationOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Address" />
              </ListItem>
              <ListItem onClick={()=>history.push('/wallet')} button>
                <ListItemIcon>
                  <AccountBalanceWalletOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Wallet" />
              </ListItem>
              {/*<ListItem onClick={()=>history.push('/payments')} button>
                <ListItemIcon>
                  <PaymentOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Payment" />
              </ListItem>*/}</>
              )}

              <ListItem onClick={()=>shareApp()} button>
                <ListItemIcon>
                  <ShareOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Share" />
              </ListItem>
              <a rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.arpangroup.pureeats" target="_blank" >
              <ListItem button>
                <ListItemIcon>
                  <StarBorderOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Rate Us" />
              </ListItem>
              </a>
            </List>
          </div>
        </div>

        {account!==null && (
        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>
        )}

        <div className="row">
          <div className="col-12">
            <List component="nav" aria-label="main mailbox folders">
              {account!==null && (
              <ListItem onClick={()=>history.push('/myOrders')} button>
                <ListItemIcon>
                  <ReceiptOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="ORDERS" secondary="View all recent orders" />
              </ListItem>
              )}
              <a rel="noopener noreferrer" href="https://wa.me/918777630556?text=Hi%20i%20need%20help%20at%20pureeats%20please%20connect%20me%20to%20a%20care%20specialist" target="_blank" >
              <ListItem button>
                <ListItemIcon>
                  <LiveHelpOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Help" secondary="Help center related you orders and queries" />
              </ListItem>
              </a>
              <ListItem onClick={()=>history.push('/legal')} button>
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="About" secondary="About and privacy policies" />
              </ListItem>
            </List>
          </div>
        </div>

        {account!==null && (
        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>
        )}

        {account!==null && (
        <div className="row">
          <div className="col-12">
            <List component="nav" aria-label="main mailbox folders">
              <ListItem onClick={()=>logout()} button>
                <ListItemIcon>
                  <ExitToAppOutlinedIcon className="text-danger" />
                </ListItemIcon>
                <ListItemText primary="LOGOUT" secondary="Signout from your current account" />
              </ListItem>
            </List>
          </div>
        </div>
        )}

    </div>
  );
}

