import React, {useState, useEffect} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {REGISTER_USER_URL} from '../../../configs';
import Axios from 'axios';
import { Link, useParams } from "react-router-dom";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useSnackbar } from 'notistack';
import { Redirect, useHistory } from "react-router-dom";

export default function SetLocation(params) {

  const { userMobile } = useParams();
    
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [hasReferral, setHasReferral] = useState(false);
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [name, setName] = useState('');
  const [referral, setReferral] = useState('');

  useEffect(()=>{
    setMobile(userMobile);
  },[userMobile])

  const signup=()=>{
    if (name==='' || mobile.length<10 || email==='') {
      enqueueSnackbar('Please enter all details.', {variant: 'error'});
    }

    let raw = {
      phone:mobile,
      name,
      email,
      referral,
      otp
    }

    Axios.post(REGISTER_USER_URL, raw)
    .then(res=>{
      console.log(res)
      if (res.data.success) {
        localStorage.setItem('user', JSON.stringify(res.data.data));
        setTimeout(()=>{
          history.push('/restaurants')
        }, 800);
        enqueueSnackbar('Account created.', {variant: 'success'});
      }else{
        enqueueSnackbar(res.data.message, {variant: 'error'});
      }
    })
    .catch(e=>console.log(e));

  }

  if(localStorage.getItem('user') !== null ){
    return(<Redirect to="/account" />)
  }

  return (
        <div className="container">
            <div className="row">

                <div className="col-sm-12 col-md-6">
                  <div style={{paddingBottom: 30, paddingTop: 10}}>
                    <Typography style={{position: 'absolute', right: 10}} variant="body1" gutterBottom>
                      <Link to="/restaurants">Skip</Link>
                    </Typography>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div style={{paddingBottom: 10}}>
                    <Typography variant="caption" gutterBottom>
                      Welcome to
                    </Typography>
                    <Typography variant="h3" gutterBottom>
                      <b>PURE EATS</b>
                    </Typography>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 p-0">

                  <div style={{padding: 25}}>

                    <div style={{paddingBottom: 10}}>
                      <Typography style={{fontSize: '0.89rem'}} variant="caption" gutterBottom>
                        <b>CREATE ACCOUNT</b>
                      </Typography>
                    </div>

                    <div className="row" style={{marginTop: 40, marginBottom: '1rem'}}>
                      <div className="col-sm-12">
                        <TextField disabled={true} value={mobile} onChange={(e)=>setMobile(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="Mobile No." />
                        <TextField onChange={(e)=>setName(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="Name" />
                        <TextField onChange={(e)=>setEmail(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="Email" />
                        <TextField onChange={(e)=>setOtp(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="OTP" />

                        {hasReferral && (
                          <TextField onChange={(e)=>setReferral(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="Referral code" />
                        )}


                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={hasReferral} onChange={(e)=>setHasReferral(e.target.checked)} name="checkedA" />}
                            label="I have referral code"
                          />
                        </FormGroup>

                      </div>
                      <div className="col-sm-12">
                        <div style={{textAlign: 'center', width: '100%', marginBottom: 5}}>
                          <Button onClick={()=>signup()} style={{marginBottom: 20, marginTop: 40, fontSize: 11, height: 45, width: '100%', backgroundColor: '#bb1229', borderRadius: 0, color: 'white'}} variant="contained" color="primary">
                            CREATE ACCOUNT
                          </Button>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div style={{Padding: 20}}>
                          <Typography className="mt-3" style={{fontSize: '0.89rem', textAlign: 'center'}} variant="h6" gutterBottom>
                            Arady have an account ? <Link style={{borderBottom: '2px solid black'}} to='login'>Login</Link>
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                
            </div>


        </div>
    
  );
}
